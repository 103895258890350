import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
export default function Secone(){
    return (
        <>
        
             <Container fluid className="__bg-primary-sl">
                <Container>
                    <Row className="fade-in">
                        <Col lg={12}>
                            <div className="__introduction">
                                <p className="text-center">
                                    Introducing
                                </p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__image_logo-sl">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/logo-am-love.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
           

        </>
    )
}

